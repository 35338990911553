<template>
	<h1 class="PageHeader">
		<slot />
	</h1>
</template>

<script>
export default {
	name: "PageHeader",
};
</script>

<style lang="scss" scoped>
.PageHeader {
	font-weight: normal;
	font-size: 2.5em;
	text-transform: uppercase;
	text-align: center;
}
</style>
