<template>
	<div class="IconList">
		<slot />
	</div>
</template>

<script>
export default {
	name: "IconList",
};
</script>

<style lang="scss">
.IconList {
	padding: 0 1em;
}
</style>
