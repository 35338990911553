<template>
	<div class="CertificateListItem">
		<div class="logo">
			<img :src="logo" />
		</div>

		<div class="info">
			<h3 class="certificate-name">{{ name }}</h3>

			<div class="links">
				<ExternalLink
					v-if="from"
					class="from"
					:href="from"
				>
					Van {{ fromFormatted }}
				</ExternalLink>

				<ExternalLink
					class="certificate"
					:href="link"
				>
					Certificaat
				</ExternalLink>
			</div>

			<p class="issued">Toegekend op: {{ issuedFormatted }}</p>
		</div>
	</div>
</template>

<script>
import formatDate from "../../assets/js/formatDate";
import ExternalLink from "../ExternalLink.vue";

export default {
	name: "CertificateListItem",
	components: {
		ExternalLink,
	},
	props: {
		name: {
			type: String,
			required: true,
		},
		logo: {
			type: String,
			required: true,
		},
		issued: {
			type: Date,
			required: true,
		},
		from: {
			type: String,
			required: false,
			validator: l => l.startsWith("http"),
		},
		link: {
			type: String,
			required: true,
			validator: l => l.startsWith("http"),
		},
	},
	computed: {
		issuedFormatted() {
			return formatDate(this.issued, false);
		},

		fromFormatted() {
			return this.from.replace(/^https?:\/\//, "");
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.CertificateListItem {
	display: flex;
	flex-direction: row;

	div.logo {
		height: 5em;
		padding-right: 1em;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	div.info {
		h3.certificate-name {
			margin: 0;
		}

		div.links {
			> .ExternalLink:not(:first-of-type) {
				&::before {
					content: "|";
					margin-left: .5em;
					margin-right: .25em;
					color: map-get($colors, "foreground");
				}
			}
		}

		p.issued {
			margin: 0;
		}
	}
}
</style>
