<template>
	<div class="PhotoHeader">
		<img class="image" src="/assets/me.jpg" alt="Ik">
	</div>
</template>

<script>
export default {
	name: "PhotoHeader",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable.scss";

.PhotoHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 246.4px;
	padding-right: 10%;
	background-image: url("../assets/images/image-background.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;

	img.image {
		width: 180px;
		height: 180px;
		border-radius: 100px;
	}
}
</style>
