const MONTHS = [{
	short: "jan.",
	long: "januari",
}, {
	short: "feb.",
	long: "februari",
}, {
	short: "mrt.",
	long: "maart",
}, {
	short: "apr.",
	long: "april",
}, {
	short: "jun.",
	long: "juni",
}, {
	short: "jul.",
	long: "juli",
}, {
	short: "aug.",
	long: "augustus",
}, {
	short: "sept.",
	long: "september",
}, {
	short: "okt.",
	long: "oktober",
}, {
	short: "nov.",
	long: "november",
}, {
	short: "dec.",
	long: "december",
}];

/**
 * @param {Date} date
 * @param {boolean} [short=true]
 * @returns {string}
 */
export default function formatDate(date, short = true) {
	let month = MONTHS[ date.getMonth() ].short;

	if (!short) month = MONTHS[ date.getMonth() ].long;

	return `${month} ${date.getFullYear()}`;
}
