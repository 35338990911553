<template>
	<div class="TimeTableItem">
		<h3
			class="header"
			:data-time="`${getStartDate()} - ${getEndDate()}`"
		>
			{{ title }}
		</h3>

		<div class="labels">
			<div
				v-if="location"
				class="location"
			>
				<fa-icon class="icon" :icon="[ 'fas', 'location-dot' ]" />

				<ExternalLink :href="location.href">{{ location.text }}</ExternalLink>
			</div>
		</div>

		<p class="information">
			<slot />
		</p>
	</div>
</template>

<script>
import formatDate from "../../assets/js/formatDate";
import ExternalLink from "../ExternalLink.vue";

export default {
	name: "TimeTableItem",
	components: {
		ExternalLink,
	},
	props: {
		title: {
			type: String,
			required: true,
			validator: h => h.length > 0 && h.length <= 60,
		},
		start: {
			type: Date,
			required: true,
		},
		end: {
			type: Date,
			required: false,
			default: null,
		},
		location: {
			type: Object,
			required: false,
			default: null,
			validator: location => {
				if (!location) return false;

				const requiredKeys = [ "text", "href" ];
				const keys = Object.keys(location);

				return requiredKeys.every( k => keys.includes(k) );
			}
		},
	},
	methods: {
		getStartDate() {
			return formatDate(this.start)
		},

		getEndDate() {
			if (!this.end) return "Heden";

			return formatDate(this.end);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.TimeTableItem {
	// last of type because items are flipped in the TimeTable
	&:not(:last-of-type) {
		margin-top: 1em;
	}

	h3.header {
		margin: 0;
		font-size: 1rem;

		&::before {
			display: inline-block;
			content: attr(data-time);
			width: map-get($time-table, "time-width");
			font-size: .9em;
			font-weight: normal;
			font-style: italic;
			opacity: .85;
		}
	}

	div.labels {
		div .icon {
			margin-right: .5em;
		}
	}

	p.information {
		margin: 0;
		margin-top: .5em;
	}
}
</style>
