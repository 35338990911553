<template>
	<h3 class="TitleDivider" :class="{ padded }">
		<slot />
	</h3>
</template>

<script>
export default {
	name: "TitleDivider",
	props: {
		padded: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable.scss";

.TitleDivider {
	display: flex;
	align-items: center;
	margin: .5em 0;

	&.padded {
		padding: 0 1em;
	}

	&::after {
		content: "";
		height: .2em;
		flex-grow: 1;
		margin-left: 1em;
		background-color: map-get($colors, "primary");
		border-radius: 50px;
	}
}
</style>
