<template>
	<div class="SkillListItem">
		<p class="title">
			{{ skill }}
		</p>

		<div class="skill-meter">
			<div
				v-for="b in allBlocks"
				:key="`block-${skill}-${b}`"
				class="skill-block"
				:class="getBlockClasses(b)"
			/>
		</div>
	</div>
</template>

<script>
import range from "../../util/range";

const MAX_SCORE = 10;

export default {
	name: "SkillListItem",
	props: {
		skill: {
			type: String,
			required: true,
			validator: s => s.length > 0,
		},
		score: {
			type: Number,
			required: true,
			validator: n => n >= 0 && n <= MAX_SCORE,
		},
	},
	computed: {
		allBlocks() {
			return range( Math.floor(MAX_SCORE / 2) );
		},
	},
	methods: {
		getBlockClasses(block) {
			const classes = [];

			const currScore = block + 1;
			const halfScore = Math.floor(this.score / 2);

			if (currScore <= halfScore)
				classes.push("filled");
			else if (this.score % 2 === 1 && block === halfScore)
				classes.push("filled-half");

			return classes;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.SkillListItem {
	p.title {
		margin: 0;
		margin-bottom: .5em;
		font-size: .9em;
	}

	div.skill-meter {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: .25em;
		width: 100%;

		div.skill-block {
			position: relative;
			height: .5em;
			background-color: transparent;
			border: .1em solid map-get($colors, "primary");
			border-radius: .3em;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				display: none;
				width: 100%;
				height: 100%;
				background-color: map-get($colors, "primary");
			}

			&.filled,
			&.filled-half {
				&::before {
					display: block;
				}
			}

			&.filled-half::before {
				width: 50%;
			}
		}
	}
}
</style>
