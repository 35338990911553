<template>
	<div class="Item">
		<fa-icon :icon="icon" class="icon" />

		<slot />
	</div>
</template>

<script>
export default {
	name: "IconListItem",
	props: {
		icon: {
			type: Array,
			required: true,
			validator: i => i.length === 2,
		},
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.Item {
	&:not(:first-of-type) {
		margin-top: map-get($icon-list, "between-margin");
	}

	.icon {
		width: map-get($icon-list, "icon-width");
		margin-right: map-get($icon-list, "icon-margin");
	}
}
</style>
