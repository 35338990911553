<template>
	<h2 class="SectionHeader">
		<slot />
	</h2>
</template>

<script>
export default {
	name: "SectionHeader",
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variable.scss";

.SectionHeader {
	$bar-margin: map-get($headers, "section-bar-margin");

	position: relative;
	margin: 0;
	text-transform: uppercase;
	text-align: center;

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 50%;
		width: map-get($headers, "section-bar-width");
		height: map-get($headers, "section-bar-height");
		background-color: map-get($colors, "primary");
		border-radius: 25px;
		transform: translateY(-50%);
	}

	&::before {
		right: $bar-margin;
	}

	&::after {
		left: $bar-margin;
	}
}
</style>
