<template>
	<div class="TimeTable">
		<slot />
	</div>
</template>

<script>
export default {
	name: "TimeTable",
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.TimeTable {
	display: flex;
	flex-direction: column-reverse;
	padding: $page-padding;
}
</style>
