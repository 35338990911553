<template>
	<a
		class="ExternalLink"
		:class="{ dark }"
		:href="href"
		target="_blank"
	>
		<slot />
	</a>
</template>

<script>
export default {
	name: "ExternalLink",
	props: {
		href: {
			type: String,
			required: true,
			validator: h => h.length > 0 && h.startsWith("http"),
		},
		dark: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@use "sass:color";

@import "../assets/scss/variable.scss";

.ExternalLink {
	$color: map-get($colors, "primary");

	color: $color;
	text-decoration: underline;

	&.dark {
		color: color.scale( $color, $lightness: -50% );
	}

	.icon {
		margin-left: .25em;
		color: currentColor;
	}
}
</style>
