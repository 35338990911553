<template>
	<div class="container">
		<aside> <!-- Side bar {{{ -->
			<PhotoHeader />

			<IconList> <!-- Icon list {{{ -->
				<IconListItem :icon="[ 'fas', 'user' ]">Joram Buitenhuis</IconListItem>
				<IconListItem :icon="[ 'fas', 'location-dot' ]">Warnsveld</IconListItem>
				<IconListItem :icon="[ 'fas', 'at' ]">joram.buitenhuis@gmail.com</IconListItem>
				<IconListItem :icon="[ 'fas', 'cake-candles' ]">14 / 03 / 2003</IconListItem>
				<IconListItem :icon="[ 'fas', 'flag' ]">Nederlandse</IconListItem>
			</IconList>

			<TitleDivider padded>Links</TitleDivider>

			<IconList>
				<IconListItem :icon="[ 'fab', 'linkedin' ]">
					<ExternalLink href="https://www.linkedin.com/in/joram-buitenhuis-43169b1b6/">Joram Buitenhuis</ExternalLink>
				</IconListItem>
				<IconListItem :icon="[ 'fab', 'github' ]">
					<ExternalLink href="https://github.com/jejbuitenhuis/">jejbuitenhuis</ExternalLink>
				</IconListItem>
				<IconListItem :icon="[ 'fas', 'link' ]">
					<ExternalLink href="https://jejb.dev">jejb.dev</ExternalLink>
				</IconListItem>
			</IconList>
			<!-- }}} -->

			<SkillList> <!-- Skill list {{{ -->
				<SkillListItem :score="10" skill="JavaScript" />
				<SkillListItem :score="9" skill="TypeScript" />

				<SkillListItem :score="8" skill="Rust" />
				<SkillListItem :score="8" skill="Java" />
				<SkillListItem :score="8" skill="PHP" />

				<SkillListItem :score="9" skill="SQL" />

				<SkillListItem :score="9" skill="Vue.js" />
				<SkillListItem :score="6" skill="React" />
				<SkillListItem :score="7" skill="Laravel" />

				<SkillListItem :score="7" skill="Bash" />

				<SkillListItem :score="9" skill="GIT" />
				<SkillListItem :score="8" skill="Docker" />
				<SkillListItem :score="8" skill="Linux" />
			</SkillList>
			<!-- }}} -->
		</aside> <!-- }}} -->

		<main>
			<PageHeader>Curriculum Vitae</PageHeader>

			<p class="about">
				Mijn naam is Joram Buitenhuis, ik ben {{ age }} jaar oud en ik
				ben geboren in Warnsveld. Ik doe de opleiding HBO-ICT aan de HAN
				in Arnhem, waar ik voor de richting Software Development heb
				gekozen. Op het moment ben ik bezig met afstuderen bij Wehkamp,
				waar ik werk aan de koppeling tussen Wehkamp en DHL. Naast
				school werk ik ook vaak aan hobby projectjes, voornamelijk in
				Rust en Vue.js.
			</p>

			<section class="work-experience"> <!-- Work experience {{{ -->
				<SectionHeader>Werkervaring</SectionHeader>

				<TimeTable>
					<TimeTableItem
						title="Junior Software Engineer"
						:start="new Date(2021, 6)"
						:end="new Date(2023, 5)"
						:location="{ text: 'RYSST Zwolle', href: 'https://rysst.nl/' }"
					>
						Bij RYSST heb ik in groepjes van vier à vijf studenten
						gewerkt aan projecten voor verschillende bedrijven. Zo
						heb ik voor <ExternalLink href="https://www.kadaster.nl/">Het Kadaster</ExternalLink>
						aan een design systeem gewerkt, waar developers met
						behulp van StoryBook alle beschikbare componenten konden
						bekijken en uitproberen. Ook ben ik voor
						<ExternalLink href="https://www.wehkamp.nl/">Wehkamp</ExternalLink>
						bezig geweest met het herschrijven van het systeem dat
						de foto's van externe providers op de website plaatst in
						Rust en heb ik meegeholpen met het vastgoed systeem van
						<ExternalLink href="https://metafoorsoftware.nl/">Metafoor Software</ExternalLink>
						in een nieuw jasje hijsen.
					</TimeTableItem>

					<TimeTableItem
						title="Meewerkstage"
						:start="new Date(2023, 1)"
						:end="new Date(2023, 5)"
						:location="{ text: 'Fringe Company', href: 'https://fringe.nl' }"
					>
						Aan het begin van mijn stage heb ik geholpen met het ontwikkelen van <ExternalLink href="https://www.fringe.nl/onderwijssystemen/peilstok/">een nieuw systeem</ExternalLink>. Later heb ik ook meegeholpen aan het oplossen van bugs in en verder uitbreiden van <ExternalLink href="https://www.fringe.nl/onderwijssystemen/online-leeromgeving-en-lms/">OnderwijsOnline</ExternalLink> en <ExternalLink href="https://www.fringe.nl/onderwijssystemen/studentvolgsysteem/">Trajectplanner</ExternalLink>.
					</TimeTableItem>

					<TimeTableItem
						title="Junior Software Developer"
						:start="new Date(2023, 5)"
						:location="{ text: 'Fringe Company', href: 'https://fringe.nl' }"
					>
						Na mijn meewerkstage heb ik een baan aangeboden gekregen en ben ik bij Fringe blijven werken.
					</TimeTableItem>

					<TimeTableItem
						title="Afstudeerstage"
						:start="new Date(2024, 2)"
						:location="{ text: 'Wehkamp', href: 'https://wehkamp.nl' }"
					>
						Tijdens mijn afstudeerstage ben ik bezig met uitzoeken hoe Wehkamp de koppeling met DHL kan migreren van een REST API naar een meer Event Driven oplossing. Ik doe eerst onderzoek naar de mogelijk oplossingsrichtingen, om vervolgens een richting te kiezen en deze te implementeren.
					</TimeTableItem>
				</TimeTable>
			</section> <!-- }}} -->

			<section class="education"> <!-- Education {{{ -->
				<SectionHeader>Opleidingen</SectionHeader>

				<TimeTable>
					<TimeTableItem
						title="HAVO - Natuur en Techniek"
						:start="new Date(2016, 6)"
						:end="new Date(2020, 4, 27)"
						:location="{ text: 'Baudartius College, Zutphen', href: 'https://baudartius.nl' }"
					>
						Diploma behaald
					</TimeTableItem>

					<TimeTableItem
						title="HBO - HBO-ICT (Propedeuse)"
						:start="new Date(2020, 6)"
						:end="new Date(2021, 5, 24)"
						:location="{ text: 'HAN University of Applied Sciences, Arnhem', href: 'https://www.han.nl/' }"
					>
						Behaald
					</TimeTableItem>

					<TimeTableItem
						title="Minor - Cloud Native Software Development"
						:start="new Date(2023, 6)"
						:end="new Date(2024, 0)"
						:location="{ text: 'Hogeschool Utrecht', href: 'https://www.hu.nl/' }"
					/>

					<TimeTableItem
						title="HBO - HBO-ICT (Software Development)"
						:start="new Date(2021, 6)"
						:location="{ text: 'HAN University of Applied Sciences, Arnhem', href: 'https://www.han.nl/' }"
					/>
				</TimeTable>
			</section> <!-- }}} -->

			<section class="certificates"> <!-- Certificates {{{ -->
				<SectionHeader>Certificaten</SectionHeader>

				<CertificateList>
					<CertificateListItem
						name="Professional Scrum Master™ I (PSM I)"
						logo="/assets/PSMI_logo.png"
						:issued="new Date(2022, 7)"
						from="https://scrum.org"
						link="https://www.credly.com/badges/726d1a8f-8f60-4aea-9cbd-4636d3fdf87b/"
					/>
				</CertificateList>
			</section> <!-- }}} -->
		</main>
	</div>
</template>

<script>
import PhotoHeader from "./components/PhotoHeader.vue";
import TitleDivider from "./components/TitleDivider.vue";

import IconList from "./components/IconList";
import IconListItem from "./components/IconList/Item.vue";

import ExternalLink from "./components/ExternalLink.vue";

import SectionHeader from "./components/SectionHeader.vue";
import PageHeader from "./components/PageHeader.vue";

import TimeTable from "./components/TimeTable";
import TimeTableItem from "./components/TimeTable/Item.vue";

import CertificateList from "./components/CertificateList";
import CertificateListItem from "./components/CertificateList/Item.vue";

import SkillList from "./components/SkillList";
import SkillListItem from "./components/SkillList/Item.vue";

const YEAR_IN_MS = 31_556_952_000;
const BIRTH_DATE = new Date(2003, 2, 14);

export default {
	name: "App",
	components: {
		PhotoHeader,
		TitleDivider,

		IconList,
		IconListItem,

		ExternalLink,

		SectionHeader,
		PageHeader,

		TimeTable,
		TimeTableItem,

		CertificateList,
		CertificateListItem,

		SkillList,
		SkillListItem,
	},
	computed: {
		age() {
			const now = Date.now();
			const diff = now - BIRTH_DATE.getTime();

			return Math.floor(diff / YEAR_IN_MS);
		},
	},
}
</script>

<style lang="scss">
@use "sass:color";
@import "./assets/scss/variable.scss";

html,
body {
	margin: 0;
	padding: 0;
	color: map-get($colors, "foreground");
	font-family: 'Raleway', sans-serif;
	font-size: 16px;
	line-height: 1.6;

	@media not print {
		background-color: #252525;
	}
}

#app {
	div.container {
		$aside-size: 35%;

		display: grid;
		grid-template-columns: $aside-size 100% - $aside-size;
		width: 800px;
		margin: 0 auto;
		background-color: #ffffff;

		aside {
			background-color: color.scale( map-get($colors, "primary"), $lightness: 75% );
		}

		main {
			p.about {
				margin: 0;
				padding: $page-padding;
				padding-top: 0;
			}

			section {
				@media print {
					page-break-inside: avoid;
				}

				ul {
					margin: 0;
				}
			}
		}
	}
}
</style>
