<template>
	<div class="SkillList">
		<TitleDivider>Vaardigheden</TitleDivider>

		<div class="skills">
			<slot />
		</div>
	</div>
</template>

<script>
import TitleDivider from "../TitleDivider.vue";

export default {
	name: "SkillList",
	components: {
		TitleDivider,
	},
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.SkillList {
	padding: .5em 1em;

	div.skills {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: .5em 1em;
		margin-top: .5em;
	}
}
</style>
