import {library} from "@fortawesome/fontawesome-svg-core";
import {faGithub, faLinkedin} from "@fortawesome/free-brands-svg-icons";
import {faArrowUpRightFromSquare, faAt, faCakeCandles, faFlag, faLink, faLocationDot, faPhone, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { createApp } from "vue";

import App from "./App.vue";

const iconList = [
	faUser,
	faLocationDot,
	faPhone,
	faAt,
	faCakeCandles,
	faFlag,
	faLink,
	faArrowUpRightFromSquare,

	faLinkedin,
	faGithub,
];

console.log(iconList);

iconList.forEach( i => library.add(i) );

createApp(App)
	.component("fa-icon", FontAwesomeIcon)
	.mount("#app");
