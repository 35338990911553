<template>
	<div class="CertificateList">
		<slot />
	</div>
</template>

<script>
export default {
	name: "CertificateList",
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable.scss";

.CertificateList {
	padding: $page-padding;
}
</style>
